import React from "react";
import PropTypes from "prop-types";

type PropType = {
  pageContext: { category: string };
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: {
        node: {
          fileAbsolutePath: string;
          excerpt: string;
          frontmatter: {
            slug: string;
            title: string;
            date: string;
            categories: string[];
          };
        };
      }[];
    };
  };
};

// Components
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import SEO from "../components/seo";
import _ from "lodash";
function Category(props: PropType) {
  const { data, pageContext } = props;
  const { category } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const categoryHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${category}"`;
  return (
    <Layout>
      <SEO
        post={{
          title: `Posts tagged with '${category}'`,
          path: `/${_.kebabCase(category)}`,
        }}
      />
      <div className="col col-12 col-xl-8 mx-auto">
        <h1 className="h4">{categoryHeader}</h1>
        <section>
          {edges.map(({ node }) => {
            return <PostLink key={node.frontmatter.slug} {...node} />;
          })}
        </section>
        <Link to="/categories" className="btn btn-secondary" role="button">
          All categories
        </Link>
      </div>
    </Layout>
  );
}

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Category;

export const pageQuery = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            slug
            title
            categories
          }
        }
      }
    }
  }
`;
