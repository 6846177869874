import React, { PropsWithChildren } from "react";
import { Link } from "gatsby";
import Tags from "./tag-list";
import CalendarIcon from "./calendar-icon";

type PropType = {
  excerpt: string;
  frontmatter: {
    date: string;
    slug: string;
    title: string;
    categories: string[];
  };
};

const PostLink = (post: PropType) => {
  const to = post.frontmatter.slug.startsWith("/posts")
    ? post.frontmatter.slug
    : `/posts${post.frontmatter.slug}`;
  return (
    <div className="blog-post mb-4 p-3 bg-white rounded">
      <h2 className="h3 mb-1">
        <QuietLink to={to}>{post.frontmatter.title}</QuietLink>
      </h2>
      <p className="blog-post-meta mb-2 text-muted">
        <CalendarIcon /> {post.frontmatter.date}
        <Tags categories={post.frontmatter.categories} />
      </p>
      <span>{post.excerpt}</span>
      <span>&nbsp;</span>
      <QuietLink to={to}>
        <span style={{ color: "#6a9fb5" }}>➦</span>
      </QuietLink>
    </div>
  );
};

export default PostLink;

function QuietLink({ to, children }: PropsWithChildren<{ to: string }>) {
  return (
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
      {children}
    </Link>
  );
}
